module MinGat.Areas.Common {

    export class TwoFactorViewModel {
        DataSource: WebApi.TwoFactorDataSource;
        Challenges: KnockoutObservableArray<TwoFactorChallengeViewModel>;
        ActiveChallengeId: KnockoutObservable<number>;
        ActiveChallengeNeedsConfiguration: KnockoutComputed<boolean>;
        VerificationCode: KnockoutObservable<string>;
        VerificationCodeDisabled: KnockoutComputed<boolean>;
        PhoneNumber: KnockoutObservable<string>;
        PhoneNumberDisabled: KnockoutComputed<boolean>;
        ManualEntryKey: KnockoutObservable<string>;
        SmsText: KnockoutObservable<string>;
        IsLoggedIn: KnockoutObservable<boolean>;
        CanConfigureChallenges: KnockoutComputed<boolean>;
        CanSelectMultipleChallenges: KnockoutComputed<boolean>;
        Delay: KnockoutObservable<number>;
        ErrorText: KnockoutObservable<string>;
        // ReSharper disable InconsistentNaming
        QRCodeWrapper: KnockoutObservable<TwoFactorQRCodeWrapper>;
        Progress: any;
        isValid: KnockoutComputed<boolean>;
        verifyCodeAndContinue: (twoFactorCode: string, twoFactorChallengeType: number) => JQueryPromise<any>;
        private username: string;
        private updateDelayInterval: any;
        private fadeOutTimeout: any;

        constructor(verifyCodeAndContinue: (twoFactorCode: string, twoFactorChallengeType: number) => JQueryPromise<any>, progress: any) {
            var self = this;
            self.DataSource = new WebApi.TwoFactorDataSource();
            self.Challenges = ko.observableArray<TwoFactorChallengeViewModel>();
            self.VerificationCode = ko.observable<string>("");
            self.PhoneNumber = ko.observable<string>("");
            self.ManualEntryKey = ko.observable<string>("");
            self.SmsText = ko.observable<string>("");
            self.IsLoggedIn = ko.observable<boolean>(false);
            self.QRCodeWrapper = ko.observable<TwoFactorQRCodeWrapper>(new TwoFactorQRCodeWrapper("qrCode"));
            self.Progress = progress;
            self.ActiveChallengeId = ko.observable<number>(null);
            self.Delay = ko.observable<number>(0);
            self.ErrorText = ko.observable<string>();
            self.ActiveChallengeNeedsConfiguration = ko.computed<boolean>(() => {

                if (self.ActiveChallengeId() == null)
                    return false;
                var activeChallenge = ko.utils.arrayFirst(self.Challenges(), (challenge: TwoFactorChallengeViewModel) => {
                    return challenge.Type === self.ActiveChallengeId();
                });

                if (activeChallenge != null)
                    return !activeChallenge.IsConfigured();
                return false;
            });
            self.CanConfigureChallenges = ko.computed<boolean>(() => {
                if (self.IsLoggedIn())
                    return true;
                else {
                    var hasAnyConfiguredChallenges = ko.utils.arrayFilter(self.Challenges(), (challenge: TwoFactorChallengeViewModel) => {
                        return challenge.IsConfigured();
                    }).length > 0;
                    if (!hasAnyConfiguredChallenges)
                        return true;
                    return false;
                }
            });

            self.CanSelectMultipleChallenges = ko.computed<boolean>(() => {
                if (self.IsLoggedIn())
                    return true;
                else {
                    var hasMultipleConfiguredChallenges = ko.utils.arrayFilter(self.Challenges(), (challenge: TwoFactorChallengeViewModel) => {
                        return challenge.IsConfigured();
                    }).length > 1;
                    if (hasMultipleConfiguredChallenges)
                        return true;
                    return false;
                }
            });

            self.VerificationCodeDisabled = ko.computed<boolean>(() => {
                return self.VerificationCode().length < 1 || self.Delay() > 0;
            });

            self.PhoneNumberDisabled = ko.computed<boolean>(() => {
                return self.PhoneNumber().length < 1 || self.Delay() > 0;
            });

            if (verifyCodeAndContinue == null) {
                self.verifyCodeAndContinue = (twoFactorCode: string, twoFactorChallengeType: number) => {
                    var challengeInputModel = { Type: twoFactorChallengeType, Code: twoFactorCode }
                    return self.Progress.waitScope.waitWhile((session) => {
                        return self.DataSource.Verify(challengeInputModel)
                            .done((result: ITwoFactorResult) => {
                                if (result.Success)
                                    self.initTwoFactorForConfiguration(self.username);
                                else {
                                    if (result.DelayedSeconds > 0)
                                        self.setDelayedStatus(result.DelayedSeconds);
                                    else
                                        self.setErrorStatus();
                                }
                                session.end();
                            });
                    });
                };
            }
            else
                self.verifyCodeAndContinue = verifyCodeAndContinue;
        }

        initTwoFactorForLogin(username: string) {
            var self = this;
            self.initTwoFactorForConfiguration(username)
                .done(() => {
                    var defaultChallenge = self.getDefautChallenge();

                    if (defaultChallenge != null) {

                        if (defaultChallenge.Type === 0) {
                            self.ActiveChallengeId(defaultChallenge.Type);
                        }
                        else if (defaultChallenge.Type === 1) {
                            self.sendSms().done((result: ITwoFactorResult) => {
                                self.ActiveChallengeId(defaultChallenge.Type);
                                self.setSmsText(result.Success, defaultChallenge.ScrambledPhoneNumber);
                            });
                        }
                    }
                    self.IsLoggedIn(false);
                });
        }


        setSmsText(success: boolean, number: string) {
            var self = this;
            if (!success)
                self.SmsText(Gatsoft.UI.Resources.get("mg.2fa.sms.failed.text"));
            else if (number == null || number.length < 1)
                self.SmsText(Gatsoft.UI.Resources.get("mg.2fa.sms.sent1.text") + " " + Gatsoft.UI.Resources.get("mg.2fa.sms.sent2.text") + ". " + Gatsoft.UI.Resources.get("mg.2fa.sms.retry.text"));
            else
                self.SmsText(Gatsoft.UI.Resources.get("mg.2fa.sms.sent1.text") + " " + number + ". " + Gatsoft.UI.Resources.get("mg.2fa.sms.retry.text"));
        }

        setDelayedStatus(delay: number) {
            var self = this;
            clearInterval(self.updateDelayInterval);
            clearTimeout(self.fadeOutTimeout);
            self.Delay(delay);
            self.setDelayErrorText();
            self.updateDelayInterval = setInterval(() => {

                if (self.Delay() === 0) {
                    clearInterval(self.updateDelayInterval);
                    self.fadeOutTimeout = setTimeout(() => {
                        $("#errorPanel").fadeOut();
                    }, 100);
                }
                self.Delay(self.Delay() - 1);
                if (self.Delay() >= 0) {
                    self.setDelayErrorText();
                }
            }, 1000);

            $("#errorPanel").fadeIn();
        }

        setDelayErrorText() {
            var self = this;
            var convertedTime = Utils.DateTime.secondsToFormattedTimeString(self.Delay());
            var timeString = convertedTime.success ? convertedTime.value : self.Delay() + " " + Gatsoft.UI.Resources.get("mg.2fa.seconds");
            self.ErrorText(Gatsoft.UI.Resources.get("mg.2fa.verification.delayed") + " " + timeString + ".");
        }

        setErrorStatus() {
            var self = this;
            self.ErrorText(Gatsoft.UI.Resources.get("mg.2fa.verification.failed"));
            clearTimeout(self.fadeOutTimeout);
            $("#errorPanel").fadeIn();
            self.fadeOutTimeout = setTimeout(() => {
                $("#errorPanel").fadeOut();
            }, 5000);
        }

        getDefautChallenge(): TwoFactorChallengeViewModel {
            var self = this;
            var defaultChallenge = ko.utils.arrayFirst(self.Challenges(), (challenge: TwoFactorChallengeViewModel) => {
                return challenge.IsDefault();
            });
            return defaultChallenge;
        }

        initTwoFactorForConfiguration(username: string): JQueryPromise<any> {
            var self = this;
            self.username = username;
            self.Challenges([]);
            self.ActiveChallengeId(null);
            self.VerificationCode("");
            self.PhoneNumber("");
            self.Delay(0);
            self.ManualEntryKey("");
            self.SmsText("");
            $("#manualEntryKey").fadeOut();
            $("#errorPanel").fadeOut();
            clearInterval(self.updateDelayInterval);
            clearTimeout(self.fadeOutTimeout);
            self.IsLoggedIn(true);
            return self.Progress.waitScope.waitWhile((session) => {
                return self.DataSource.GetChallenges()
                    .done((challenges) => {
                        ko.utils.arrayForEach(challenges, (challenge: ITwoFactorChallenge) => {
                            self.Challenges.push(new TwoFactorChallengeViewModel(challenge));
                        });
                        session.end();
                    });
            });
        }

        configure(challenge: TwoFactorChallengeViewModel) {
            var self = this;
            self.VerificationCode("");
            self.PhoneNumber("");
            if (challenge.Type === 0) {
                self.Progress.waitScope.waitWhile((session) => {
                    self.DataSource.GetTotpConfigurationModel()
                        .done((model: ITimeBasedOneTimePasswordConfiguration) => {
                            self.QRCodeWrapper().createQRCode(model.KeyUri);
                            self.ActiveChallengeId(challenge.Type);
                            self.ManualEntryKey(model.ManualEntryKey);
                            session.end();
                        });
                });
            }
            else if (challenge.Type === 1) {
                self.ActiveChallengeId(challenge.Type);
            }
        }

        removeConfiguration(challenge: TwoFactorChallengeViewModel) {
            var self = this;

            Gatsoft.UI.MessageBox({
                messageType: "question",
                buttons: "yes-no",
                title: Gatsoft.UI.Resources.get("mg.2fa.confirm.remove.title"),
                heading: null,
                message: Gatsoft.UI.Resources.get("mg.2fa.confirm.remove.text"),
                resultCallback(e, result) {
                    if (result.modalResult === "yes") {
                        var challengeInputModel = { Type: challenge.Type };
                        self.Progress.waitScope.waitWhile((session) => {
                            self.DataSource.Remove(challengeInputModel)
                                .done(() => {
                                    self.initTwoFactorForConfiguration(self.username);
                                    session.end();
                                });
                        });
                    }
                }
            });
        };

        confirmTwoFactor() {
            var self = this;
            if (self.VerificationCode().length < 1)
                return;
            self.verifyCodeAndContinue(self.VerificationCode(), self.ActiveChallengeId());
        }

        onEnterConfirm(d, e) {
            var self = this;
            if (e.keyCode === 13)
                self.confirmTwoFactor();
            return true;
        }

        configureSms() {
            var self = this;
            var configurationInputModel = { Username: self.username, PhoneNumber: self.PhoneNumber() };
            self.Progress.waitScope.waitWhile((session) => {
                self.DataSource.ConfigureSmsChallenge(configurationInputModel)
                    .done((result: ITwoFactorResult) => {
                        if (!result.Success) {
                            if (result.DelayedSeconds > 0)
                                self.setDelayedStatus(result.DelayedSeconds);
                            else
                                self.setErrorStatus();
                        }
                        session.end();
                    });
            });
        }

        onEnterConfigureSms(d, e) {
            var self = this;
            if (e.keyCode === 13)
                self.configureSms();
            return true;
        }

        sendSms(): JQueryPromise<ITwoFactorResult> {
            var self = this;
            return self.DataSource.SendSms()
                .done((result: ITwoFactorResult) => {
                    if (!result.Success) {
                        if (result.DelayedSeconds > 0)
                            self.setDelayedStatus(result.DelayedSeconds);
                        else
                            self.setErrorStatus();
                    }
                });

        }

        changeChallenge() {
            var self = this;
            self.ActiveChallengeId(null);
        }

        select(challenge: TwoFactorChallengeViewModel) {
            var self = this;
            self.ActiveChallengeId(challenge.Type);
        }

        showManualEntryKey() {
            $("#manualEntryKey").fadeIn();
        }
    }

    interface ITwoFactorChallenge {
        Type: number;
        IsConfigured: boolean;
        TypeName: string;
        IsDefault: boolean;
        ScrambledPhoneNumber: string;
    }

    interface ITwoFactorResult {
        Success: boolean;
        DelayedSeconds: number;
    }

    interface ITimeBasedOneTimePasswordConfiguration {
        ManualEntryKey: string;
        KeyUri: string;
    }

    export class TwoFactorChallengeViewModel {

        Type: number;
        IsConfigured: KnockoutObservable<boolean>;
        TypeName: string;
        IsDefault: KnockoutObservable<boolean>;
        Expanded: KnockoutObservable<boolean>;
        HelpText: string;
        ScrambledPhoneNumber: string;


        constructor(challenge: ITwoFactorChallenge) {
            var self = this;

            self.Type = challenge.Type;
            self.IsConfigured = ko.observable<boolean>(challenge.IsConfigured);
            self.TypeName = challenge.TypeName;
            self.IsDefault = ko.observable<boolean>(challenge.IsDefault);
            self.Expanded = ko.observable<boolean>(false);
            if (self.Type === 0)
                self.HelpText = Gatsoft.UI.Resources.get("mg.2fa.totp.help");
            if (self.Type === 1)
                self.HelpText = Gatsoft.UI.Resources.get("mg.2fa.sms.help");
            self.ScrambledPhoneNumber = challenge.ScrambledPhoneNumber;
        }

        toggle() {
            var self = this;
            self.Expanded(!self.Expanded());

            var id = "#info_" + self.Type;

            if (self.Expanded()) {
                $(id).fadeIn();
            }

            if (!self.Expanded()) {
                $(id).fadeOut();
            }
        }
    }

    export class TimeBasedOneTimePasswordConfigurationModel {

        ManualEntryKey: string;
        KeyUri: string;

        constructor(challenge: ITimeBasedOneTimePasswordConfiguration) {
            var self = this;

            self.ManualEntryKey = challenge.ManualEntryKey;
            self.KeyUri = challenge.KeyUri;
        }
    }

    declare var QRCode: any;
    export class TwoFactorQRCodeWrapper {

        private _QRCode: any;
        private _ElementId: string;
        private _Data: any;

        constructor(elementId: string) {
            var self = this;
            self._ElementId = elementId;
        }

        clearQrCode() {
            var self = this;
            $("#" + self._ElementId).html("");
        }

        createQRCode(data: string) {
            var self = this;
            self._Data = data;
            $("#" + self._ElementId).html("");
            self._QRCode = new QRCode(self._ElementId,
                {
                    text: data,
                    width: 100,
                    height: 100,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H
                });
            $("#" + self._ElementId).removeAttr("title");
        }
    }
}